export default {
  indexEndpoint: '/tickets?status=',
  dropdownCustomersEndpoint: '/tickets-customer',
  dropdownCustomersContactsEndpoint: '/tickets-customer/',
  storeEndpoint: '/tickets',
  replyEndpoint: '/tickets-reply/',
  editEndpoint: '/tickets/',
  updateEndpoint: '/tickets-update/',
  deleteEndpoint: '/tickets/',
  assignedEndpoint: '/tickets-assigned/',
  resendEndpoint: '/tickets-resend/',
}
