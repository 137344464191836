<template>
  <b-card-code
    title=""
  >
    <b-card-body>
      <b-row
        v-if="accessPermissions('ticket.index')"
      >
        <b-col
          md="4"
          sm="12"
        >
          <b-form-group
            label="Filtrar por"
            label-for="type"
          >
            <b-form-select
              v-model="type"
              :options="option"
              @change="onChange"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-start">
            <b-button
              v-if="accessPermissions('ticket.store')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              :to="{ name: 'tickets-create' }"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Nuevo</span>
            </b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <b-form-input
                v-if="accessPermissions('ticket.index')"
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Buscar..."
                class="d-inline-block"
              />
            </b-form-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            v-if="accessPermissions('ticket.list.index')"
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="fields"
            :items="rows"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(ticket_id)="data">
              <div>
                {{ data.item.ticket_id }}
              </div>
            </template>

            <template #cell(customer)="data">
              <div>
                {{ data.item.customer }}
              </div>
            </template>

            <template #cell(contact)="data">
              <div>
                {{ data.item.contact }}
              </div>
            </template>

            <template #cell(subject)="data">
              <div>
                {{ data.item.subject }}
              </div>
            </template>

            <template #cell(category)="data">
              <div
                class="badge badge-secondary text-white"
                :style="{backgroundColor: `${data.item.color} !important`}"
              >
                {{ data.item.category_name }}
              </div>
            </template>

            <template #cell(status)="data">
              <b-badge
                :variant="data.item.statusColor"
              >
                {{ data.item.statusName }}
              </b-badge>
            </template>

            <template #cell(created_by)="data">
              <div>
                {{ data.item.created_name }}
              </div>
            </template>

            <template #cell(created_at)="data">
              <b-badge>
                {{ data.item.created_at }}
              </b-badge>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template
                  v-if="accessPermissions('ticket.update') || accessPermissions('ticket.destroy')"
                  v-slot:button-content
                >
                  <feather-icon
                    icon="SettingsIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="accessPermissions('ticket.update')"
                  :to="{ name: 'tickets-edit', params: { id: data.item.id } }"
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="accessPermissions('ticket.destroy')"
                  @click="onHandleDelete(data.item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-start">
            <b-form-group
              label="Por Página"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="custom-search d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card-code>
</template>

<script>
import {
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BPagination,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import router from '@/router'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import TicketsService from './services/TicketsService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'
import { getItem, setItem } from '@/libs/storage'

export default {
  name: 'TicketPage',
  components: {
    BCardBody,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BPagination,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BTable,
    BCardCode,
    BBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      option: [
        { value: 'all', text: 'Todos' },
        { value: 'me', text: 'Para mí' },
      ],
      perPage: 50,
      pageOptions: [10, 20, 50, 100],
      totalRows: 0,
      currentPage: 1,
      filter: null,
      fields: [
        {
          key: 'ticket_id', label: 'Ticket ID',
        },
        {
          key: 'customer', label: 'Cliente / Sucursal',
        },
        {
          key: 'contact', label: 'Contacto',
        },
        {
          key: 'subject', label: 'Asunto',
        },
        {
          key: 'category', label: 'Categoría',
        },
        {
          key: 'status', label: 'Estado',
        },
        {
          key: 'created_by', label: 'Creado',
        },
        {
          key: 'created_at', label: 'Fecha',
        },
        {
          key: 'action', label: '',
        },
      ],
      rows: [],
      type: '',
    }
  },
  watch: {
    filter(value) {
      setItem('search_tickets', value)
    },
    $route(to) {
      this.onHandleList(to.name.replace('tickets-', ''))
    },
  },
  mounted() {
    this.filter = getItem('search_tickets') === undefined ? '' : getItem('search_tickets')
    this.type = this.option[0].value
  },
  async created() {
    await this.onHandleList(this.status)
  },
  methods: {
    onChange() {
      this.onHandleList(this.status)
    },
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    async onHandleRedirect(id) {
      if (this.accessPermissions('ticket.show')) {
        await router.push({ name: 'tickets-edit', params: { id: id } })
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    async onHandleDelete(data) {
      if (this.accessPermissions('ticket.destroy')) {
        await this.$swal({
          title: '¿Está seguro que desea eliminar el ticket?',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            TicketsService.handleDelete(data).then(response => {
              if (response.data.data) {
                this.onHandleList()
              }
            }).catch(error => this.errorResp(error))
          }
        })
      }
    },
    async onHandleList(status) {
      if (this.accessPermissions('ticket.index')) {
        await TicketsService.handleList(status === 'all' ? null : status, (this.type === 'all' || this.type === '') ? 'todos' : 'me').then(response => {
          let data = []

          if (response.data.data.length > 0) {
            const array = response.data.data

            array.map((item, index) => {
              array[index].customer = `${item.customer} ${item.customer_branches.length > 0 ? '/ '+item.customer_branches[0].full_name : ''}`

              if (item.status === 'Closed') {
                array[index].statusName = 'Finalizado'
                array[index].statusColor = 'success'
              }

              if (item.status === 'In Progress') {
                array[index].statusName = 'En Proceso'
                array[index].statusColor = 'info'
              }

              if (item.status === 'On Hold') {
                array[index].statusName = 'Pendiente'
                array[index].statusColor = 'danger'
              }
            })

            data = array
          }

          this.rows = data.length === 0 ? null : data
          this.totalRows = data.length
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>
