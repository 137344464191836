import ticketsDefaultConfig from './TicketsDefaultConfig'
import axiosIns from '@/libs/axios'

class CustomersService {
    config = { ...ticketsDefaultConfig }

    axios = null

    constructor() {
      this.axios = axiosIns
    }

    async handleList(data, filter) {
      const result = await this.axios.get(`${this.config.indexEndpoint}${data}&filter=${filter}`)

      return result
    }

    // async handleDropdownCustomer() {
    //   const result = await this.axios.get(`${this.config.dropdownCustomersEndpoint}`)
    //
    //   return result
    // }

    async handleDropdownCustomerContact(id) {
      const result = await this.axios.get(`${this.config.dropdownCustomersContactsEndpoint}${id}`)

      return result
    }

    async handleStore(data) {
      const result = await this.axios.post(this.config.storeEndpoint, data)

      return result
    }

    async handleReply(id, data) {
      const result = await this.axios.post(`${this.config.replyEndpoint}${id}`, data)

      return result
    }

    async handleEdit(id) {
      const result = await this.axios.get(`${this.config.editEndpoint}${id}`)

      return result
    }

    async handleUpdate(id, data) {
      const result = await this.axios.post(`${this.config.updateEndpoint}${id}`, data)

      return result
    }

    async handleDelete(id) {
        const result = await this.axios.delete(`${this.config.deleteEndpoint}${id}`)

        return result
    }

    async handleAssigned(id, data) {
      const result = await this.axios.put(`${this.config.assignedEndpoint}${id}`, data)

      return result
    }

    async handleResend(id) {
      const result = await this.axios.get(`${this.config.resendEndpoint}${id}`)

      return result
    }
}

export default new CustomersService()
